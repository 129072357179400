import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextComponent } from './input-text/input-text.component';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldModule as MatFormFieldModule,
} from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { InputDayMonthYearComponent } from './input-date-of-birth/input-day-month-year.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TypographyModule } from '@components/typography/typography.module';
import { InputCountryComponent } from './dropdown-country/input-country.component';
import { InputSuffixDirective } from './input-suffix.directive';
import { InputPrefixDirective } from './input-prefix.directive';
import { InputTextAreaComponent } from '@components/input/input-textarea/input-textarea.component';
import { InputNumberComponent } from '@components/input/input-number/input-number.component';
import { IConfig, NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { TrimDirective } from './input-trim.directive';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    InputTextComponent,
    InputDayMonthYearComponent,
    DropdownComponent,
    InputCountryComponent,
    InputSuffixDirective,
    InputPrefixDirective,
    InputTextAreaComponent,
    InputNumberComponent,
    TrimDirective,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    TypographyModule,
    NgxMaskDirective,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'auto',
        appearance: 'standard',
        hideRequiredMarker: true,
      },
    },
    provideEnvironmentNgxMask(maskConfig),
  ],
  exports: [
    InputTextComponent,
    InputDayMonthYearComponent,
    DropdownComponent,
    InputCountryComponent,
    InputSuffixDirective,
    InputPrefixDirective,
    InputTextAreaComponent,
    InputNumberComponent,
    TrimDirective,
  ],
})
export class InputModule {}
