import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { AmenityGroupAccordionComponent } from './components/amenity-group-accordion/amenity-group-accordion.component';
import { AmenityGroupComponent } from './components/amenity-group/amenity-group.component';
import { AmenityMenuComponent } from './components/amenity-menu.component';
import { IconModule } from '@components/icon/icon.module';
import { TippyDirective } from '@ngneat/helipopper';
import { CheckboxModule } from '@components/checkbox/checkbox.module';
import { TypographyModule } from '@components/typography/typography.module';
import { RadioButtonLegacyModule } from 'src/app/libs/component-lib/radio-button-legacy.module';

@NgModule({
  declarations: [
    AmenityGroupAccordionComponent,
    AmenityGroupComponent,
    AmenityMenuComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    RouterModule,
    MatTableModule,
    IconModule,
    TippyDirective,
    CheckboxModule,
    TypographyModule,
    RadioButtonLegacyModule,
  ],
  exports: [AmenityGroupAccordionComponent, AmenityGroupComponent],
})
export class PropertyAmenityModule {}
