<dialog-wrapper width="sm" [position]="ls ? 'center' : 'bottom'">
  <dialog-header (dialogClose)="closeDialog()" title="Your profile picture"></dialog-header>
  <dialog-content>
    <div class="d-flex justify-content-center cropper-height">
      <image-cropper
        [imageFile]="file"
        [maintainAspectRatio]="true"
        [aspectRatio]="1"
        [format]="format"
        (cropperReady)="onCropperReady($event)"
        (imageCropped)="imageCropped($event)"
        [cropperMinWidth]="minWidth"
        [resizeToWidth]="minWidth"
        [cropper]="cropper"
        roundCropper="true"
        onlyScaleDown="true"
      ></image-cropper>
    </div>
  </dialog-content>
  <dialog-actions>
    <div class="d-flex justify-content-center mx-16">
      <button
        button
        appMouseHold
        variant="inline"
        icon="minus"
        color="secondary"
        class="pb-8"
        data-test="avatar-size-minus"
        (mousehold)="decreaseDimension()"
      ></button>
      <mat-slider
        class="slider slider-primary mb-8 flex-grow-1"
        [min]="minDimension"
        [max]="maxDimension"
        [(value)]="dimension"
        (input)="dimensionSliderChange($event)"
      ></mat-slider>
      <button
        button
        appMouseHold
        variant="inline"
        color="secondary"
        icon="plus"
        class="pb-8"
        data-test="avatar-size-plus"
        (mousehold)="increaseDimension()"
      ></button>
    </div>
    <div class="reg-container-half-btn">
      <div class="pr-8">
        <button
          button
          color="secondary"
          variant="contained"
          class="w-100"
          (click)="closeDialog()"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="reg-container-half-btn">
      <div class="pl-8">
        <button button color="primary" variant="contained" data-test="button-continue" class="w-100" (click)="save()">
          Confirm
        </button>
      </div>
    </div>
  </dialog-actions>
</dialog-wrapper>
