import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, NgZone } from '@angular/core';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import {
  GoogleTagManagerModule,
  GoogleTagManagerService,
} from 'angular-google-tag-manager';
import { ImageCropperModule } from 'ngx-image-cropper';
import { env as environment } from './dynamic-environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './core/handlers/global-err-handler';
import { AuthHttpInterceptor } from './core/interceptors/auth.interceptor';
import { IsoDateHttpInterceptor } from './core/interceptors/iso-date-http.interceptor';
import { AuthService } from './core/services/auth.service';
import { SentryService } from './core/services/sentry.service';
import { WindowSizeService } from './core/services/window-size.service';
import { AuthModule } from './modules/auth/auth.module';
import { NavbarModule } from './modules/navbar/navbar.module';
import { initGtm } from './utils/gtm.utils';
import { HomeModule } from './modules/home/home.module';
import { HelpscoutBeaconService } from './core/services/helpscout-beacon.service';
import { InternetConnectionInterceptor } from './core/interceptors/internet-connection.interceptor';
import { ImpactService } from './core/services/impact.service';
import { GoogleAnalytics4Service } from './core/services/google-analytics4.service';
import { UserActivityService } from './core/services/user-activity.service';
import { ToggleModule } from './core/modules/toggle/toggle.module';
import { ChatModule } from './modules/chat/chat.module';
import { PropertyAmenityModule } from './modules/property/property-amenity/property-amenity.module';
import { SmartlookService } from './core/services/smartlook.service';
import { MetaPixelService } from './core/services/meta-pixel.service';
import { TranslocoRootModule } from './transloco-root.module';
import 'reflect-metadata';
import { NavigationMenuMobileModule } from './modules/navigation/navigation-menu/mobile/navigation-menu-mobile.module';

export function auth0InitFactory(authService: AuthService) {
  return () => authService.init();
}

export function userActivityInitFactory(
  userActivityService: UserActivityService,
) {
  return () => userActivityService.init();
}

export function gtmInitFactory(
  router: Router,
  ngZone: NgZone,
  gtmService: GoogleTagManagerService,
) {
  return () => initGtm(router, ngZone, gtmService);
}

export function windowSizeInitFactory(windowSizeService: WindowSizeService) {
  return () => windowSizeService.init();
}

export function sentryInitFactory(sentryService: SentryService) {
  return () => sentryService.init();
}

export function helpscoutBeaconInit(beaconService: HelpscoutBeaconService) {
  return () => beaconService.init();
}

export function impactInit(impactService: ImpactService) {
  return () => impactService.init();
}

export function smartlookInit(smartlookService: SmartlookService) {
  return () => smartlookService.init();
}

function metaPixelInit(metaPixelService: MetaPixelService) {
  return () => metaPixelService.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ToggleModule,
    HammerModule,
    BrowserAnimationsModule,
    A11yModule,
    PortalModule,
    ImageCropperModule,
    GoogleTagManagerModule.forRoot({
      id: environment.ga.gtmId,
    }),
    // Routing
    AppRoutingModule,
    // App
    NavbarModule,
    HomeModule,
    ChatModule,

    ImageCropperModule,
    // App
    CoreModule,
    AuthModule,
    PropertyAmenityModule,
    NavbarModule,
    HttpClientModule,
    TranslocoRootModule,
    NavigationMenuMobileModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: auth0InitFactory,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: userActivityInitFactory,
      deps: [UserActivityService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: gtmInitFactory,
      deps: [Router, NgZone, GoogleTagManagerService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: windowSizeInitFactory,
      deps: [WindowSizeService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: sentryInitFactory,
      deps: [SentryService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: helpscoutBeaconInit,
      deps: [HelpscoutBeaconService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: impactInit,
      deps: [ImpactService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: smartlookInit,
      deps: [SmartlookService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: metaPixelInit,
      deps: [MetaPixelService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternetConnectionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IsoDateHttpInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'auto',
        appearance: 'standard',
        hideRequiredMarker: true,
      },
    },
    GoogleAnalytics4Service,
    provideHttpClient(withFetch()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
