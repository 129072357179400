import { Component, Inject, Injector } from '@angular/core';
import { AbstractDialog } from '../../../../../libs/component-lib/components/dialog/abstract-dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';

@Component({
  selector: 'reset-password-confirmation-dialog',
  template: `
    <dialog-wrapper
      width="sm"
      [position]="(largeScreen$ | async) ? 'center' : 'bottom'"
    >
      <dialog-header
        title="Password reset link sent"
        (dialogClose)="closeDialog()"
      ></dialog-header>
      <dialog-content>
        <div class="justify-content-between">
          We sent link to reset your password to
          <span typography variant="h5">{{ email }}</span
          >. Check your email to continue the process.
        </div>
      </dialog-content>
    </dialog-wrapper>
  `,
})
export class ResetPasswordConfirmationDialogComponent extends AbstractDialog {
  largeScreen$ = this.breakpointService.largeScreen$;
  email: string;

  constructor(
    injector: Injector,
    private readonly breakpointService: BreakpointService,
    @Inject(MAT_DIALOG_DATA) data: { email: string },
  ) {
    super(injector);
    this.email = data.email;
  }
}
