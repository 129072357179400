import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Renderer2,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export type DialogWidth = 'xs' | 's' | 'sm' | 'md' | 'lg' | 'xl' | false;

export type DialogPosition = 'center' | 'top' | 'bottom';

export type DialogLayout =
  | 'title-content'
  | 'content'
  | 'title-content-actions'
  | 'content-actions';

export type DialogOverflow = 'auto' | 'visible' | 'scroll' | 'hidden';

/**
 * @author Libor Staněk, Radek Chocholouš
 */
@Component({
  selector: 'dialog-wrapper',
  template: '<div #dialogRef><ng-content></ng-content></div>',
})
export class DialogWrapperComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('dialogRef', { static: true })
  dialogRef: ElementRef;

  /** Only applied for "center" dialog position */
  @Input()
  width: DialogWidth = 'sm';

  @Input()
  position: DialogPosition = 'center';

  @Input()
  layout: DialogLayout = 'title-content-actions';

  @Input()
  backdropClose = true;

  @Input()
  overflow: DialogOverflow = 'auto';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @SkipSelf() @Optional() private readonly matDialogRef: MatDialogRef<any>,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `dialog-wrapper`);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    // click on backdrop
    if (event.target === this.elementRef.nativeElement && this.backdropClose) {
      if (this.matDialogRef) {
        this.matDialogRef.close();
      }
    }
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(this.dialogRef.nativeElement, `dialog`);
    this.renderer.setStyle(
      this.dialogRef.nativeElement,
      'overflow',
      this.overflow,
    );
    this.renderer.addClass(
      this.dialogRef.nativeElement,
      `dialog-layout-${this.layout}`,
    );
    this.renderer.addClass(
      this.dialogRef.nativeElement,
      `dialog-position-${this.position}`,
    );
    this.renderer.addClass(
      this.dialogRef.nativeElement,
      `dialog-width-${this.width}`,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.position) {
      this.renderer.removeClass(
        this.dialogRef.nativeElement,
        `dialog-position-${changes.position.previousValue}`,
      );
      this.renderer.addClass(
        this.dialogRef.nativeElement,
        `dialog-position-${changes.position.currentValue}`,
      );
    }
    if (this.dialogRef && changes.maxWidth) {
      this.renderer.removeClass(
        this.dialogRef.nativeElement,
        `dialog-width-${changes.maxWidth.previousValue}`,
      );
      this.renderer.addClass(
        this.dialogRef.nativeElement,
        `dialog-width-${changes.maxWidth.currentValue}`,
      );
    }
  }
}
